import { defineComponent as _defineComponent } from 'vue'
import SVGWorldMap from "@/assets/images/bg/world.svg";

import RedeemInvitation from "@ems/containers/SupplyChainManagement/TheForm/RedeemInvitation.Form.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'RedeemInvitation',
  setup(__props, { expose: __expose }) {
  __expose();


const __returned__ = { get SVGWorldMap() { return SVGWorldMap }, RedeemInvitation }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})