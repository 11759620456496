import { defineComponent as _defineComponent } from 'vue'
import { Field, Form } from "vee-validate";
import { useRouter, useRoute } from "vue-router";
import { object, string } from "yup";
import { ref } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'RedeemInvitation.Form',
  setup(__props, { expose: __expose }) {
  __expose();

const router = useRouter();
const route = useRoute();
const schema = object({
  InvitationCode: string().required("The Invitation code is required"),
});

const Invitation = {
  InvitationCode: route.query.code,
};

const isHaveAccount = ref(false);

const onSubmit = (values: any) => {
  isHaveAccount.value
    ? router.push({
        path: "/login",
        query: { "redeem-invitation": `${values.InvitationCode}` },
      })
    : router.push({
        path: "/redeem-registration",
        query: { "redeem-invitation": `${values.InvitationCode}` },
      });
};

const __returned__ = { router, route, schema, Invitation, isHaveAccount, onSubmit, get Field() { return Field }, get Form() { return Form }, get useRouter() { return useRouter }, get useRoute() { return useRoute }, get object() { return object }, get string() { return string }, ref }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})